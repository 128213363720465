import React from 'react';
import {NavLink} from 'react-router-dom';
import './styles.scss';
import logo from '../../images/logo.png';

const SiteHeader = () => {
    return (
        <div className="row site-header justify-content-center">
            <div className="col-6">
                <NavLink to={''}
                         className="d-flex justify-content-center align-items-center site-logo">
                    <img src={logo} className="site-logo--img" alt="logo"/>
                    <span className="site-logo--text">webcam.uman.ua</span>
                </NavLink>
            </div>
        </div>
    )
};

export default React.memo(SiteHeader);
