import React, { useState, useContext, memo } from 'react';

import { useTranslation } from "react-i18next";

import Tag from './Tag';
import SearchField from '../SearchField';
import { TagsContext } from "../../../Context";

import triangle from '../../../images/triangle.png';
import './styles.scss';



const HashtagCloud = ({ selectedTags, changeSelectedTags, clearSelectedTags, searchValue, setSearchValue }) => {
    const [t, i18n] = useTranslation();
    const [showAllTags, setShowAllTags] = useState(false);
    const tags = useContext(TagsContext);

    const switchShowAllTags = () => setShowAllTags(prevShowAllTags => !prevShowAllTags);

    return (
        <div className="row justify-content-center hashtag">
            <SearchField
                value={searchValue}
                onChange={setSearchValue}
            />
            <Tag
                name={t('all')}
                isSelected={selectedTags.length === 0}
                changeSelectedTags={clearSelectedTags}
            />
            {tags.map(tag => {
                const isSelected = selectedTags.includes(tag.id);
                if (showAllTags || tag.in_top || isSelected) {
                    return <Tag
                        key={tag.id}
                        id={tag.id}
                        isSelected={isSelected}
                        name={i18n.language !== 'en' ? tag.name : tag.name_eng}
                        changeSelectedTags={changeSelectedTags}
                    />
                }
                return null;
            }
            )}
            <button
                className={`hashtag-switcher ${showAllTags && 'hashtag-switcher--active'}`}
                onClick={switchShowAllTags}
            ><img src={triangle} alt="triangle" className="hashtag-switcher--triangle" />
            </button>
        </div>
    )
};

export default memo(HashtagCloud);
