import React, {useState, memo} from 'react';
import {useTranslation} from "react-i18next";

const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
};

const AuthForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [hasAuthErrors, setAuthErrors] = useState(false);

    const [t, i18n] = useTranslation();

    const onSubmitForm = (e) => {
        e.preventDefault();
        const bodyParams = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
            },
            body: JSON.stringify({username, password})
        };
        fetch('/authenticate', bodyParams)
            .then(response => response.json())
            .then(response => {
                if (response && response.result) {
                    const url = new URL(window.location.href);
                    const next = url.searchParams.get('next');
                    window.location.href = next || '/'
                } else {
                    setAuthErrors(true);
                }
            })
            .catch(e => {
                setAuthErrors(true);
                console.log(e);
            })
    };

    return (
        <form className='justify-content-center' method='POST'>
            <p className='modal--text'>{t('authenticate_invite_text')}</p>
            <div className='row justify-content-md-center'>
                <div className="col-12 col-md-9 modal--input-wrapper">
                    <input
                        type="text"
                        className='modal--input'
                        placeholder={t('username')}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
            </div>
            <div className='row justify-content-md-center'>
                <div className="col-12 col-md-9 modal--input-wrapper">
                    <input
                        type="password"
                        className='modal--input'
                        placeholder={t('password')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
            </div>
            <div className='row justify-content-md-center'>
                <div className="col-12 col-md-6">
                    {hasAuthErrors && <p className='modal--text modal--text--error'>{t('wrong_credentials')}.</p>}
                </div>
                <div className="col-12 col-md-3">
                    <button
                        type='submit'
                        className='modal--submit-button'
                        onClick={onSubmitForm}
                    >ОК
                    </button>
                </div>
            </div>
        </form>
    )
};

export default memo(AuthForm);
