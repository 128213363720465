import React, {memo} from 'react';

import {useTranslation} from "react-i18next";
import PageHeader from "../Components/PageHeader/index";
import Banner from "../Components/Banner/index";


const InfoPage = () => {
    const [t, i18n] = useTranslation();
    return (
        <>
            <PageHeader text={t('info')}/>
            <h3>{t('info_header')}</h3>
            <p>{t('info_first_paragraph')}</p>
            <p>{t('info_second_paragraph')}</p>
            <p>{t('info_third_paragraph')}</p>
            <Banner />
        </>
    )
};

export default memo(InfoPage);
