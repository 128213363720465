import React, {memo} from 'react';
import './styles.scss';

const PageHeader = ({text}) => (
    <div className="row">
        <div className="col">
            <h1 className='page-header'>{text}</h1>
        </div>
    </div>
);

export default memo(PageHeader, () => true);