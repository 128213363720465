import React, {memo} from 'react';
import {useTranslation} from "react-i18next";

import Text from '../../Text';
import './styles.scss';

const PerPageSwitcher = ({perPage, changePerPage}) => {
    const [t, i18n] = useTranslation();
    return (
        <div className="d-none d-lg-block col-lg-8">
            <div className={`per-page-switcher ${!perPage && 'per-page-switcher--hidden'}`}>
                <Text text={'per_page'}/>
                {perPage && [16, 32, 64].map(i => (
                    <button
                        key={i}
                        className={`per-page-switcher--link ${perPage === i && 'per-page-switcher--link--active'}`}
                        tabIndex="1"
                        onClick={() => changePerPage(i)}
                    >{i} {t('items')}.
                    </button>
                ))}
            </div>
        </div>
    )
};

export default memo(PerPageSwitcher);
