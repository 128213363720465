import React from 'react';
import {useTranslation} from "react-i18next";

import Text from '../../Text';
import './styles.scss';
import camGrey from '../../../images/cam_grey.png';
import camWhite from '../../../images/cam_white.png';
import merkerGrey from '../../../images/marker_grey.png';
import markerWhite from '../../../images/marker_white.png';

const ViewSwitcher = ({showMap, toggleMap}) => {
    const [t, i18n] = useTranslation();
    return (
        <div className="col-xs-5 col-lg-2 align-self-center view-switcher">
            <Text text='view'/>
            <button
                className={`view-switcher--link ${!showMap && 'view-switcher--link--active'}`}
                title={t('list')}
                onClick={toggleMap}
            >
                <img src={showMap ? camGrey : camWhite} alt="cam"/>
            </button>
            <button
                className={`view-switcher--link ${showMap && 'view-switcher--link--active'}`}
                title={t('map')}
                onClick={toggleMap}
            >
                <img src={showMap ? markerWhite : merkerGrey} alt="marker"/>
            </button>
        </div>
    )
};

export default React.memo(ViewSwitcher);
