import i18n from "i18next";
import {initReactI18next} from "react-i18next";

i18n.use(initReactI18next).init({
    resources: {
        ua: {
            translations: {
                'top-menu-text': 'Онлайн трансляція веб-камер м. Умань',
                'cameras': 'Камери',
                'info': 'Інформація',
                'gallery': 'Галерея',
                'webcams': 'Веб камери',
                'per_page': 'Виводити на сторінці',
                'items': 'шт',
                'installed_cams': 'Встановлені камери',
                'view': 'Вид:',
                'list': 'Список',
                'map': 'Карта',
                'all': 'Всі',
                'about_header': 'Камери відеоспостереження м. Умань',
                'about_first_paragraph': 'Проект http://webcam.uman.ua створений компанією“ТІМ”. Даний ресурс дає можливість вільно переглядати частину публічних веб камер, що встановлені в м. Умань та камер системи відеоспостереження “Безпечне місто”. Проект “Безпечне місто” створений завдяки співпраці влади і підприємців м. Умань.',
                'about_second_paragraph': 'Сьогодні система камер відеоспостереження це один із невід\'ємних елементів сучасного міста. Відео з веб камер транслюється в режимі онлайн. Онлайн трансляція з веб камер дозволяє спостерігати за подіями на вулицях Умані в будь - якому місці і в будь - який час. Веб камери встановлені у найбільш людних та популярних місцях Умані. Мережа веб камер продовжує розширюватися і розвиватись. Постійно встановлюються нові камери відеоспостереження по місту.',
                'about_third_paragraph': 'Як переглядати веб камери через інтернет? Ви можете переглядати веб камери онлайн зайшовши на сайт http://webcam.uman.ua. Якість відеопотоку залежить від швидкості доступу до Інтернет. Для комфортного перегляду веб камер потрібна швидкість з\'єднання не менше 2 мб/с.',
                'info_header': 'Даний ресурс створений компанією ТІМ',
                'info_first_paragraph': 'webcam.uman.ua дає можливість переглядати частину публічних веб камер встановлених в уманському регіоні. Веб камери встановлені в різних місцях, а саме біля державних установ, на головних вулицях міста та сіл, на вокзалах та зупинках, в місцях найбільшого скупчення місцевих жителів та туристів. Відео з веб камер транслюється в режимі онлайн, тому в будь-якому місці і в будь-який час Ви можете слідкувати за подіями в місцях, які Вас цікавлять. Мережа постійно розширюється, встановлюються нові камери відеоспостереження, відкривається все більше місць для онлайн перегляду.',
                'info_second_paragraph': 'На даному сайті також розміщена частина веб камер, що встановлені в рамках проекту “Безпечне місто”. Проект створений уманською міською радою та підприємцями міста для покращення криміногенної обстановки та підтримки громадського порядку. Відео з камер транслюється на пульт поліції 24 години на добу, що забезпечує постійний контроль з боку працівників правоохоронних органів.',
                'info_third_paragraph': 'Як переглядати веб камери через інтернет? Ви можете переглядати веб камери онлайн зайшовши на сайт http://webcam.uman.ua. Якість відеопотоку залежить від швидкості доступу до Інтернет. Для комфортного перегляду веб камер потрібна швидкість з\'єднання не менше 2 мбіт/с.',
                'authenticate_invite_text': 'Введіть логін та пароль від Вашого облікового запису ТІМ.',
                'wrong_credentials': 'Невірний логін чи пароль',
                'username': 'Логін',
                'password': 'Пароль',
                'logout_confirm_text': 'Ви дійсно бажаєте вийти з облікового запису ',
                'logout': 'Вийти',
                'developed_by': 'Розробка',
            },

        },
        en: {
            translations: {
                'top-menu-text': 'Uman web cameras online streams',
                'cameras': 'Cameras',
                'info': 'Information',
                'gallery': 'Gallery',
                'webcams': 'Webcams',
                'per_page': 'Per-page',
                'items': 'cams',
                'installed_cams': 'Cameras',
                'view': 'View:',
                'list': 'List',
                'map': 'Map',
                'all': 'All',
                'about_header': 'Uman CCTV cameras',
                'about_first_paragraph': 'The project http://webcam.uman.ua was created by company “ТІМ”. This resource allows to watch streams of public webcams placed about Uman and security cameras of “Safe City“ system for free.',
                'about_second_paragraph': 'At present, the system of CCTV cameras is one of the integral elements of a modern city. Video from webcams is streamed online. The stream from web cameras allows you to observe Uman street life anywhere and anytime. The number of web cameras continues to expand.',
                'about_third_paragraph': 'The mode of watching web cameras via the Internet. You can watch webcams at http://webcam.uman.ua. The quality of video stream depends on the Internet speed. For a comfortable webcam experience, the connection speed must be at least 2 Mbps.',
                'info_header': 'The project was created by company “ТІМ”',
                'info_first_paragraph': 'webcam.uman.ua allows to watch streams of some of public webcams placed about Uman region. Webcams are mounted in different places, namely, near state institutions, on the main streets of the city and villages, at stations and bus stops, in the most popular and crowded by locals and tourists places. Video from webcams is streamed online so you can keep track of the city events anytime and anywhere. The network is constantly expanding, new webcams are being placed, and consequently more and more places are available for online viewing.',
                'info_second_paragraph': 'This web site also hosts a part of security cameras of “Safe City“ project. The project was created by Uman City Council and entrepreneurs of the city in order to improve the criminogenic situation and for the enforcement of law. Video from the cameras is streamed to the police station 24 hours a day, providing continuous monitoring by law enforcement officials.',
                'info_third_paragraph': 'The mode of watching web cameras via the Internet. You can watch webcams at http://webcam.uman.ua. The quality of video stream depends on the Internet speed. For a comfortable webcam experience, the connection speed must be at least 2 Mbps.',
                'authenticate_invite_text': 'Enter username and password from your TIM account.',
                'wrong_credentials': 'Wrong username or password',
                'username': 'Username',
                'password': 'Password',
                'logout_confirm_text': 'Are you sure you want to logout from account ',
                'logout': 'Logout',
                'developed_by': 'Developed by',
            },
        },

    },
    lng: localStorage.getItem('language') || "ua",
    fallbackLng: "ua",
    debug: false,

    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false,

    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

export default i18n;