import React from 'react';
import {NavLink} from 'react-router-dom';
import { withRouter } from 'react-router';
import './styles.scss';
import {useTranslation} from "react-i18next";

const NavMenu = (props) => {
    const [t, i18n] = useTranslation();
    const {pathname} = props.location;
    const isCamerasPage = pathname === '/' || pathname.startsWith('/camera/');
    const isInfoPage = pathname === '/info';
    const isGalleryPage = pathname === '/gallery';
    const isPrivatePage = !isCamerasPage && !isInfoPage && !isGalleryPage;
    return (
        <div className="container nav-menu-wrapper">
            <div className={`row justify-content-center nav-menu ${isPrivatePage && 'nav-menu--no-shadow'}`}>
                <div
                    className={`col nav-menu--item ${isCamerasPage ? 'nav-menu--item--current' : ''}`}>
                    <NavLink to='/' className="nav-menu--link">{t("cameras")}</NavLink>
                </div>
                <div
                    className={`col nav-menu--item ${isInfoPage ? 'nav-menu--item--current' : ''}`}>
                    <NavLink to='/info' className="nav-menu--link">{t("info")}</NavLink>
                </div>
                <div
                    className={`col nav-menu--item ${isGalleryPage || isPrivatePage ? 'nav-menu--item--current' : ''}`}>
                    <NavLink to='/gallery' className="nav-menu--link">{t("gallery")}</NavLink>
                </div>
            </div>
        </div>
    )
};

export default React.memo(withRouter(NavMenu));
