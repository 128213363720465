import React, {memo} from 'react';
import LazyImage from "../LazyImage";
import {NavLink} from 'react-router-dom';
import './styles.scss';
import {useTranslation} from "react-i18next";

const PlaylistLink = ({item, isLazy = false, className = 'col-xs-12 col-sm-6 col-lg-3'}) => {
    const [t, i18n] = useTranslation();
    const cameraName = i18n.language === 'ua' || !item.name_eng ? item.name : item.name_eng;
    return (
        <div className={className}>
            <NavLink to={`/${item.slug}`} className="playlist-link">
                {isLazy ? (
                    <LazyImage
                        sourceSet={item.logo}
                        alt={item.slug}
                        className="playlist-link--logo"
                    />
                ) : (
                    <img src={item.logo} alt={item.slug} className="playlist-link--logo"/>
                )}

                <div className="playlist-link--text">
                    <span>{cameraName}</span>
                </div>
            </NavLink>
        </div>
    )
};

export default memo(PlaylistLink);
