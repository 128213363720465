import React from 'react';
import {useInView} from 'react-intersection-observer';

const LazyImage = ({sourceSet, ...props}) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0,
    });

    return (
        <div ref={ref}>
            {inView ? (
                <img
                    {...props}
                    srcSet={sourceSet}
                    alt=''
                />
            ) : null}
        </div>
    )
};

export default LazyImage;
