import React from 'react';

const Tag = ({name, id, isSelected, setActive, changeSelectedTags}) => {
    const changeIsActive = () => {
        changeSelectedTags(id);
    };
    return (
        <button
            className={`hashtag-item ${isSelected && 'hashtag-item--active'}`}
            onClick={changeIsActive}
        >#{name}
        </button>
    )
};

export default React.memo(Tag);
