import React, {useContext, useEffect, memo} from 'react';

import Player from '../Components/Player/VideoJSPlayer';

import {CurrentCameraContext} from "../Context";


const TimPage = () => {
  const [camera, setUpCamera] = useContext(CurrentCameraContext);

  useEffect(() => {
    fetch(`/api/SBONYDYO`)
        .then(response => response.json())
        .then(response => setUpCamera(response))
        .catch(e => console.log(e));
    return () => setUpCamera(null);
  }, []);

  if (!camera) return null;
  return <Player camera={camera} />;
};

export default memo(TimPage);
