import React, {useContext, memo} from 'react';
import {useTranslation} from "react-i18next";
import Camera from "../Camera";
import {CamerasContext, CurrentCameraContext} from "../../Context";


const NearestCameras = () => {
    const cameras = useContext(CamerasContext);
    const [currentCamera, setUpCamera] = useContext(CurrentCameraContext);
    const [t, i18n] = useTranslation();
    if (!currentCamera || !currentCamera.nearest) {
        return null;
    }

    const onCameraPreviewClick = (camera) => {
        setUpCamera(camera);
        window.history.replaceState('page', 'Title', `/camera/${camera.id}`);
    }
    const nearestCameras = cameras.filter(cam => currentCamera.nearest.includes(cam.id));
    return (
        <div className="d-none d-xl-block col-md-3">
            {nearestCameras.map(cam => (
                <Camera
                    key={cam.id}
                    camera={cam}
                    locale={i18n.language}
                    setUpCamera={onCameraPreviewClick}
                />
            ))
            }
        </div>
    )
};

export default memo(NearestCameras);
