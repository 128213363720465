import React from 'react';

import AdSense from 'react-adsense';

const AdSenseBanner = (props) => {
    return (
        <div className="col">
            <AdSense.Google
                key={Math.random()}
                {...props}
            />
        </div>
    );
};

export default AdSenseBanner;