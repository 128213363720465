import React, {useContext, memo} from 'react';

import LazyImage from '../LazyImage';
import './styles.scss';
import {BannersContext} from "../../Context";

const Banner = ({slug = null}) => {
    const banners = useContext(BannersContext);
    if (!banners.length) {
        return null;
    }

    const getRandomBanner = () => {
        let pageBanners = slug ? banners.filter(b => b.slug === slug) : banners;
        if (!pageBanners.length) {
            pageBanners = banners;
        }
        return pageBanners[Math.floor(Math.random() * pageBanners.length)]
    };

    const banner = getRandomBanner();
    return (
        <div className="row">
            <a className="col-12"
               href={banner.redirect_url}
               target="_blank"
               rel="noopener noreferrer"
            >
                <LazyImage
                    sourceSet={`${banner.mobile} 992w, ${banner.desktop} 1920w`}
                    alt='banner'
                    className='banner'
                />
            </a>
        </div>
    )
};

export default memo(Banner, () => true);
