import React from "react";
import {useContext} from "react";
import {useTranslation} from "react-i18next";
import {PrivatePageContext} from "../../Context";
import './styles.scss';


const PlaylistHeader = () => {
    const [privatePage, _] = useContext(PrivatePageContext);
    const [t, i18n] = useTranslation();
    if (!privatePage) {
        return null;
    }
    if (privatePage.banner) {
        const {banner, banner_mobile} = privatePage;
        return (
            <div className="playlist-header">
                <img
                    srcSet={`${banner_mobile ? banner_mobile + ' 992w, ' : ''}${banner} 1920w`}
                    alt="" className="playlist-header--img"/>
            </div>
        )
    }
    if (privatePage.name) {
        const name = i18n.language !== 'en' || !privatePage.name_eng ? privatePage.name : privatePage.name_eng;
        return (
            <div className="playlist-header playlist-header--no-img">
                <h1 className='playlist-header--text'>{name}</h1>
            </div>
        )
    }
};

export default PlaylistHeader;
