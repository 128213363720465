import React, {memo} from 'react';
import './styles.scss';

const Camera = ({camera, locale, setUpCamera}) => {
    const name = locale !== 'en' || !camera.name_eng ? camera.name : camera.name_eng;
    return (
        <a className="cam" onClick={() => setUpCamera(camera)}>
            <img className="cam--img"
                 src={camera.screenshot}
                 alt={name}
                 onError={e => e.target.src = '/static/default.jpg'}
            />
            <span className="cam--text">{name}</span>
        </a>
    )
};

export default memo(Camera);
