import React, {useContext} from 'react';

import VideoJSPlayer from "./VideoJSPlayer";
import {CurrentCameraContext} from '../../Context';

import './styles.scss';


const Player = () => {
    const [camera, _] = useContext(CurrentCameraContext);
    return (
        <VideoJSPlayer camera={camera}/>
    )
};

export default Player;
