import React, {memo} from 'react';

import './styles.scss';

const CurrentCameraInfo = ({currentCamera}) => (
    <div className='camera-text'>
        <h4>{currentCamera.name}</h4>
        <p>{currentCamera.keywords}</p>
    </div>
)

export default memo(CurrentCameraInfo);