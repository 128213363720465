import React, {memo, useContext} from 'react';

import {useTranslation} from "react-i18next";

import PlaylistLink from '../PlaylistLink';
import {PlaylistsContext} from "../../Context";
import './styles.scss';

const PrivatePlaylists = () => {
    const [t, i18n] = useTranslation();
    const playlists = useContext(PlaylistsContext);
    return (
        <div className="row justify-content-center private-cam-wrapper">
            <div className="col-12">
                <h2>{t('gallery')}</h2>
            </div>
            {playlists.map(item => (
                <PlaylistLink
                    key={item.id}
                    isLazy
                    item={item}
                />
                )
            )}
        </div>
    )
};

export default memo(PrivatePlaylists);
