import React, {memo, useContext, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {withRouter} from 'react-router';

import CamerasMenu from "../Components/CamerasMenu";
import Banner from "../Components/Banner";
import PrivatePlaylists from "../Components/PrivatePlaylists";
import AboutText from "../Components/AboutText";
import PageHeader from "../Components/PageHeader";
import CurrentCamera from "../Components/CurrentCamera";
import AdSenseBanner from "../Components/AdSenseBanner";
import {CamerasContext, CurrentCameraContext} from "../Context";
import './CamerasPage.scss';
import AdSense from "react-adsense";


const CamerasPage = (props) => {
    const [t, _] = useTranslation();
    const cameras = useContext(CamerasContext);
    const [currentCamera, setUpCamera] = useContext(CurrentCameraContext);

    useEffect(() => {
        const needSetNewCamera = (!currentCamera && cameras.length) || (currentCamera && !currentCamera.is_published);
        if (needSetNewCamera) {
            const cameraId = parseInt(props.match.params.id);
            const camera = cameraId ? findCamera(cameraId) : getRandomCamera();
            setUpCamera(camera);
        }
    }, [cameras, props.match.params.id]);

    useEffect(() => {
        return () => {
            document.title = 'Онлайн трансляція веб-камер м.Умань';
            setUpCamera(null);
        }
    }, []);

    const getRandomCamera = () => {
        const camerasInRandom = cameras.filter(c => c.in_random);
        return camerasInRandom[Math.floor(Math.random() * camerasInRandom.length)];
    };

    const findCamera = (cameraId) => {
        return cameras.find(cam => cam.id === cameraId);
    };

    const onCameraPreviewClick = (camera) => {
        setUpCamera(camera);
        window.history.replaceState('page', 'Title', `/camera/${camera.id}`);
    };

    return (
        <>
            <PageHeader text={t('webcams')}/>
            <CurrentCamera currentCamera={currentCamera}/>
            <div className="row" style={{paddingTop: '16px'}}>
                <AdSenseBanner
                    client='ca-pub-4355267271530397'
                    slot='9140981204'
                    style={{display: 'block', textAlign: 'center'}}
                    format='fluid'
                    layout='in-article'
                    responsive='true'
                />
            </div>
            {cameras.length > 0 && <CamerasMenu cameras={cameras} setUpCamera={onCameraPreviewClick}/>}
            <Banner/>
            <div className="row">
                <AdSenseBanner
                    client='ca-pub-4355267271530397'
                    slot='3491206884'
                    style={{display: 'block', textAlign: 'center'}}
                    format='fluid'
                    layout='in-article'
                    responsive='true'
                />
            </div>
            <PrivatePlaylists/>
            <AboutText/>
        </>
    )
};

export default memo(withRouter(CamerasPage));
