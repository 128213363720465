import React, {memo, useContext} from 'react';
import Slider from "react-slick";
import {useTranslation} from "react-i18next";

import PlaylistLink from '../PlaylistLink';
import AdSenseBanner from "../AdSenseBanner";
import CaroucelRow from './CaroucelRow';
import {PlaylistsContext, TagsContext, CamerasContext} from "../../Context";
import './styles.scss';

const sliderSettings = {
    slidesToShow: 4,
    slidesToScroll: 2,
    infinite: false,
    dots: false,
    lazyLoad: true,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const Carousel = () => {
    const playlists = useContext(PlaylistsContext);
    const tags = useContext(TagsContext);
    const cameras = useContext(CamerasContext);
    const [t, i18n] = useTranslation();

    const popularCameras = [...cameras].sort((a, b) => a.views < b.views ? 1 : -1).slice(0, 10);
    return (
        <>
            <Slider {...sliderSettings} className='carousel'>
                {playlists.map(item => (
                    <PlaylistLink item={item} className={'col'} key={item.id}/>
                ))}
            </Slider>
            <div className="container" style={{paddingBottom: '16px'}}>
                <div className="row">
                    <AdSenseBanner
                        client='ca-pub-4355267271530397'
                        slot='9140981204'
                        style={{display: 'block', textAlign: 'center'}}
                        format='fluid'
                        layout='in-article'
                        responsive='true'
                    />
                </div>
            </div>
            <CaroucelRow
                name={'Популярні'}
                cameras={popularCameras}
                locale={i18n.language}
                settings={sliderSettings}
            />
            {tags.map(tag => (
                <CaroucelRow
                    key={tag.id}
                    name={i18n.language !== 'en' || !tag.name_eng ? tag.name : tag.name_eng}
                    cameras={cameras.filter(camera => camera.tags.includes(tag.id))}
                    locale={i18n.language}
                    settings={sliderSettings}
                />
            ))}
        </>
    )
};

export default memo(Carousel);
