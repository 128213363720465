import React, {memo} from 'react';
import './styles.scss';

import footer from '../../images/footer.jpg';

const SiteFooter = () => (
    <footer className='site-footer' style={{backgroundImage: `url(${footer})`}} />
);

export default memo(SiteFooter);
