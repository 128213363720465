import React from "react";
import videojs from 'video.js';
import {withTranslation} from 'react-i18next';
import videoStyles from 'video.js/dist/video-js.min.css';

class VideoJSPlayer extends React.Component {
    player = null;

    componentDidMount() {
        this.player = videojs(this.videoNode, {
            autoplay: true,
            controls: true,
            preload: 'auto',
            fluid: true,
            responsive: true,
            aspectRatio: '16:9',
            techOrder: [
                'html5',
                // 'flash',
            ],
        });

        this.player.on('fullscreenchange', () => {
            this.videoNode.style.objectFit = this.player.isFullscreen() ? 'contain' : 'fill';
            const videoContainer = document.querySelector('.video-js video');
            if (videoContainer) {
                this.videoNode.style.objectFit = this.player.isFullscreen() ? 'contain' : 'fill';
            }
        });

        this.player.on('error', () => {
            const error = this.player.error();
            console.log(new Date(Date.now()).toLocaleString(), error.code, error.message);
            if (error && error.code === 3) {
                this.setSource();
            }
        })

        this.setSource();
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.camera !== this.props.camera || nextProps.i18n.language !== this.props.i18n.language;
    }

    componentDidUpdate() {
        this.setSource();
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    }

    setSource = () => {
        const {camera} = this.props;
        if (this.props.camera && this.player) {
            const streamerUrl = 'https://webcam.uman.ua:30443';
            let source;
            if (camera.stream) {
                source = `${streamerUrl}/webcams/${camera.stream}/playlist.m3u8`;
            } else if (camera.video) {
                source = `${streamerUrl}/${camera.video}`;
            } else {
                return;
            }
            this.player.src(source);
            this.player.play();
            const live = document.getElementsByClassName('vjs-live-display')[0];
            if (live) {
                live.innerHTML = this.props.i18n.language !== 'en' || !camera.name_eng ? camera.name : camera.name_eng;
            }
        }
    }

    render() {
        return (
            < div data-vjs-player>
                < video
                    muted
                    playsInline
                    ref={node => this.videoNode = node}
                    className="video-js vjs-big-play-centered"
                />
            </div>
        )
    }
}

export default withTranslation()(VideoJSPlayer);