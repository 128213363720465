import React, {memo} from 'react';

import Camera from '../../Camera';
import {useTranslation} from "react-i18next";

const CamerasPreviews = ({cameras, className = '', setItem}) => {
    const [t, i18n] = useTranslation();
    return (
        <div className={`row justify-content-center ${className}`}>
            {cameras.map(item => (
                <div className='col-xs-12 col-sm-6 col-lg-4 col-xl-3' key={item.id}>
                    <Camera
                        camera={item}
                        locale={i18n.language}
                        setUpCamera={setItem}
                    />
                </div>
            ))}
        </div>
    )
};

export default memo(CamerasPreviews);
