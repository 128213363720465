import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import Text from '../Text';
import AuthButton from './AuthButton';
import './styles.scss';

const UA = 'ua';
const ENG = 'en';

const TopMenu = (props) => {
    const [t, i18n] = useTranslation();
    const currentLanguage = i18n.language;

    const toggleLanguage = (newLanguage) => {
        if (newLanguage === currentLanguage) {
            return
        }
        i18n.changeLanguage(
            currentLanguage === UA ? ENG : UA,
            () => localStorage.setItem('language', newLanguage)
        );
    };
    const languages = [ENG, UA];

    return (
        <div className="row">
            <div className="col">
                <div className="container">
                    <div className="row top-menu">
                        <div className="col-6 top-menu--text">
                            <Text text='top-menu-text'/>
                        </div>
                        <div className="col-6 top-menu--right">
                            {languages.map(lang => (
                                <span
                                    key={lang}
                                    onClick={() => toggleLanguage(lang)}
                                    className={`top-menu--link ${currentLanguage === lang && 'top-menu--link--disabled'}`}
                                >{lang === ENG ? 'ENG' : 'UA'}</span>
                            ))}
                            <AuthButton/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default memo(TopMenu);
