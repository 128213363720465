import React, {PureComponent} from 'react';

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import AdSense from 'react-adsense';

import CamerasPage from './Pages/CamerasPage';
import InfoPage from './Pages/InfoPage';
import GalleryPage from './Pages/GalleryPage';
import PrivatePage from './Pages/PrivatePage';
import TimPage from './Pages/TimPage';
import TopMenu from './Components/TopMenu';
import SiteHeader from './Components/SiteHeader';
import NavMenu from './Components/NavMenu';
import PlaylistHeader from './Components/PlaylistHeader';
import SiteFooter from './Components/SiteFooter';
import Copyright from './Components/Copyright';
import UserModal from './Components/UserModal';
import AdSenseBanner from './Components/AdSenseBanner';
import './App.scss';

import {
    CamerasContext,
    TagsContext,
    PlaylistsContext,
    BannersContext,
    CurrentCameraContext,
    PrivatePageContext,
    ModalWindowStateContext,
} from "./Context";


export default class App extends PureComponent {
    state = {
        cameras: [],
        tags: [],
        playlists: [],
        banners: [],
        currentCamera: window.camera || null,  // from public.index.html
        privatePage: window.privatePage || null,  // from public.index.html
        showUserModal: false,
    };

    componentDidMount() {
        window.fetch(`/api/cameras/`)
            .then(response => response.json())
            .then(responseData => this.setState(responseData))
            .catch(e => console.log(e));

        const {currentCamera} = this.state;
        if (currentCamera) {
            this.addCameraView(currentCamera.id);
        }
        this.checkUrlParams();
    }

    componentDidCatch(error, errorInfo) {
        console.log(error);
        console.log(errorInfo);
    }

    checkUrlParams = () => {
        const url = new URL(window.location.href);
        const auth = url.searchParams.get('auth');
        const next = url.searchParams.get('next');

        if (auth && !window.user) {
            this.setState({showUserModal: true});
        } else if (window.user && next) {
            window.location.href = next;
        }
    };

    setCurrentCamera = (camera) => {
        const sameCmera = camera === this.state.currentCamera;
        this.setState({currentCamera: camera}, () => {
            this.toTop();

            document.title = camera ? `${camera.name} - Веб камера м. Умань` : 'Онлайн трансляція веб-камер м.Умань';

            if (sameCmera) {
                return;
            }

            if (camera) {
                this.addCameraView(camera.id);

                try {
                    window.ga('set', 'page', `/camera/${camera.id}`);
                    window.ga('send', 'pageview');
                } catch (e) {
                    console.log(e);
                }
            }
        });
    };

    addCameraView = (cameraId) => {
        window.fetch(
            `/api/camera/${cameraId}/view/`,
            {
                method: 'POST',
                body: JSON.stringify({username: window.user || null}),
            }
        );
    };

    setPrivatePage = (page) => this.setState({privatePage: page});

    toTop = () => {
        try {
            window.scroll({top: 0, behavior: 'smooth'});
        } catch (e) {
            window.scrollTo(0, 0);
        }
    };

    toggleUserModal = () => this.setState(state => ({showUserModal: !state.showUserModal}));

    render() {
        const {
            cameras, tags, playlists, banners, currentCamera, privatePage, showUserModal,
        } = this.state;
        return (
            <Router>
                <header className="container-fluid">
                    <ModalWindowStateContext.Provider value={[showUserModal, this.toggleUserModal]}>
                        <TopMenu/>
                        <SiteHeader/>
                        <NavMenu/>
                        <UserModal/>
                    </ModalWindowStateContext.Provider>
                </header>
                <PrivatePageContext.Provider value={[privatePage, this.setPrivatePage]}>
                    <PlaylistHeader/>
                </PrivatePageContext.Provider>
                <main className="page-wrapper">
                    <div className="container">
                        <CamerasContext.Provider value={cameras}>
                            <TagsContext.Provider value={tags}>
                                <PlaylistsContext.Provider value={playlists}>
                                    <BannersContext.Provider value={banners}>
                                        <CurrentCameraContext.Provider value={[currentCamera, this.setCurrentCamera]}>
                                            <Switch>
                                                {['/', '/camera/:id'].map(path => (
                                                    <Route exact path={path} component={CamerasPage} key={path}/>
                                                ))}
                                                <Route exact path='/info' component={InfoPage}/>
                                                <Route exact path='/SBONYDYO' component={TimPage}/>
                                                <Route exact path='/gallery' component={GalleryPage}/>
                                                <PrivatePageContext.Provider
                                                    value={[privatePage, this.setPrivatePage]}>
                                                    <Route exact path='/:slug' component={PrivatePage}/>
                                                </PrivatePageContext.Provider>
                                            </Switch>
                                        </CurrentCameraContext.Provider>
                                    </BannersContext.Provider>
                                </PlaylistsContext.Provider>
                            </TagsContext.Provider>
                        </CamerasContext.Provider>
                        <Copyright/>
                    </div>
                </main>
                <div className="container">
                    <div className="row">
                        <AdSenseBanner
                            client='ca-pub-4355267271530397'
                            slot='8887397007'
                            style={{display: 'block'}}
                            format='auto'
                            responsive='true'
                        />
                    </div>
                </div>
                <SiteFooter/>
            </Router>
        )
    }
}

