import React, {memo} from 'react';
import CurrentCameraInfo from "../CurrentCameraInfo";
import Player from "../Player";
import NearestCameras from "../NearestCameras";

const CurrentCamera = ({currentCamera}) => {
    return (
        <div className="row">
            <div className="col-sm-12 col-xl-12 player-wrapper">
                {currentCamera && <CurrentCameraInfo currentCamera={currentCamera} />}
                <Player/>
            </div>
            {/*<NearestCameras/>*/}
        </div>
    )
}

export default memo(CurrentCamera);
