import React, {memo} from 'react';

import './styles.scss';

const Pagination = ({pages, currentPage, setCurrentPage}) => {
    const paginator = Array(pages).fill(0).map((_, i) => i + 1);
    return (
        <div className="row justify-content-center">
            <div className="col-12 paginator">
                {pages > 1 && paginator.map((page) => (
                    <button key={page}
                            className={`paginator--item ${page === currentPage && 'paginator--item--active'}`}
                            onClick={() => setCurrentPage(page)}
                    >
                        {page}
                    </button>
                ))}
            </div>
        </div>
    )
};

export default memo(Pagination);
