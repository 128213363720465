import React from 'react';
import {useTranslation} from "react-i18next";

const Text = ({text}) => {
    const [t, i18n] = useTranslation();
    return (
        <span>{t(text)}</span>
    )
};

export default Text;
