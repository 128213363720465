import React, {memo} from 'react';
import {useTranslation} from "react-i18next";
import PageHeader from "../Components/PageHeader";
import Carousel from "../Components/Carousel";

const GalleryPage = () => {
    const [t, i18n] = useTranslation();
    return (
        <div className='container'>
            <PageHeader text={t('gallery')}/>
            <Carousel />
        </div>
    )
};

export default memo(GalleryPage);
