import React, {memo} from 'react';

import { NavLink } from 'react-router-dom';

const CameraLink = ({camera, locale}) => {
    const name = locale !== 'en' || !camera.name_eng ? camera.name : camera.name_eng;
    return (
        <div className="col">
            <NavLink to={`/camera/${camera.id}`} className="cam">
                <img className="cam--img"
                     src={camera.screenshot}
                     alt={name}
                />
                <span className="cam--text">{name}</span>
            </NavLink>
        </div>
    )
};

export default memo(CameraLink);
