import React, { memo } from 'react';

import './styles.scss';

const SearchField = ({ value, onChange }) => {
    return (
        <input
            type="text"
            placeholder='Пошук камер'
            className='search-field'
            value={value}
            onChange={e => onChange(e.target.value)}
        />
    )
}

export default memo(SearchField);
