import React, {useContext, memo} from 'react';

import Drawer from 'react-drag-drawer'
import AuthForm from './AuthForm';
import Logout from './Logout';

import './styles.scss';
import {ModalWindowStateContext} from "../../Context";

const UserModal = () => {
    const [showModal, toggleModal] = useContext(ModalWindowStateContext);
    const user = window.user;

    return (
        <div>
            <Drawer
                open={showModal}
                onRequestClose={toggleModal}
                modalElementClass={'modal'}
            >
                {user ? <Logout user={user} /> : <AuthForm />}
            </Drawer>
        </div>
    )
};

export default memo(UserModal);
