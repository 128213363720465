import React, {useContext, memo} from 'react';
import userGrey from "../../images/user_grey.png";
import {ModalWindowStateContext} from '../../Context';

const AuthButton = () => {
    const [_, toggleModal] = useContext(ModalWindowStateContext);
    return (
        <button
        onClick={toggleModal}
        className='auth-button'
    >
        <img src={userGrey} alt="user"/>
    </button>
    )
};

export default memo(AuthButton);
