import React, {memo} from 'react';
import Slider from "react-slick";

import CameraLink from './CameraLink';


const CaroucelRow = ({name, cameras, locale, settings}) => {
    return (
        <div>
            <h3 className="carousel--header">{name}</h3>
            <Slider {...settings} className='carousel'>
                {cameras.map(camera => (
                    <CameraLink
                        key={camera.id}
                        camera={camera}
                        locale={locale}
                    />
                ))}
            </Slider>
        </div>
    )
}

export default memo(CaroucelRow);
