import React from 'react';
// import React, {memo, useContext, useEffect} from 'react';
import {withRouter} from "react-router-dom";
// import {useTranslation} from "react-i18next";

// import Player from '../Components/Player';
import Banner from '../Components/Banner';
import CamerasPreviews from '../Components/CamerasMenu/CamerasPreviews';
// import {PrivatePageContext, CurrentCameraContext} from '../Context';
import './PrivatePlaylistPage.scss';


const PrivatePage = (props) => {
  // const [t, i18n] = useTranslation();
  // const [c, setUpCamera] = useContext(CurrentCameraContext);
  // const [privatePage, setPrivatePage] = useContext(PrivatePageContext);
  //
  // useEffect(() => {
  //   if (!privatePage) {
  //     const {slug} = props.match.params;
  //     fetch(`/api/playlist/${slug}`)
  //       .then(response => response.json())
  //       .then(response => {
  //         setPrivatePage(response);
  //       })
  //       .catch(e => console.log(e));
  //   }
  //   return () => setPrivatePage(null);
  // }, []);
  //
  // useEffect(() => {
  //   if (privatePage && privatePage.playlist.length) {
  //     setUpCamera(privatePage.playlist[0]);
  //   }
  // }, [privatePage]);
  //
  // if (!privatePage) {
  //   return null;
  // }
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          {/*<Player/>*/}
          <h2>До уваги мешканців будинку!</h2>
          <h3>Припинено онлайн-трансляцію камер відеоспостереження.
            Зверніть увагу, що система відеоспостереження продовжує працювати, і у разі потреби ви можете отримати відео
            архіви у голів ОСББ або відповідальних осіб.
          </h3>
          <div style={{fontSize: '14px', paddingTop: '24px'}}>
            <p>СБУ закликає власників вуличних веб камер припинити онлайн-трансляції, бо ворог може використовувати їх
              для обстрілів України.</p>
            <p>Служба безпеки закликає власників вуличних веб камер припинити онлайн-трансляції зі своїх пристроїв, а
              громадян – повідомляти про виявлені стріми з таких камер на
              <a style={{color: '#007bff'}} target="_blank" href="https://t.me/stop_russian_war_bot"> офіційний чат-бот
                СБУ</a>.</p>
            <p>Насамперед це стосується автоматичної відеофіксації обстановки довкола житлових і соціальних споруд,
              дорожньо-транспортних, промислових і торговельних об’єктів. рф використовує вразливість сучасних вебкамер,
              щоб здійснювати ракетні обстріли по Україні та коригувати їх у режимі реального часу.</p>
            <p>Інформацію з камер ворог може отримувати з онлайн-трансляції через стрімінгові платформи або через
              хакерський «злам».</p>
            <p>Також СБУ наголошує: заборонено знімати та оприлюднювати фото- і відеоматеріали щодо діяльності Сил
              оборони та наслідків ворожих обстрілів. Поширення таких записів є злочином, який передбачає покарання до
              12 років тюрми.
              Сьогодні, коли вся Україна успішно протистоїть підлому і підступному ворогу, вкрай важливо не дати
              окупантам навіть найменшої можливості для коригування своїх обстрілів.
              Тож просимо бути пильними і дотримуватися безпекових рекомендацій!</p>
            <p><a style={{color: '#007bff'}} target="_blank"
                  href="https://www.facebook.com/SecurSerUkraine/posts/pfbid02EyiXzSrH8sxpRPDYMCaMHeacX8tymtQ3Z9ztUPb7nBUS8t8Jqce7ZaTXqP5qKRoGl">https://www.facebook.com/SecurSerUkraine</a></p>
          </div>
        </div>
      </div>
      {/*<CamerasPreviews*/}
      {/*    cameras={privatePage.playlist}*/}
      {/*    className='playlist'*/}
      {/*    setItem={setUpCamera}*/}
      {/*/>*/}
      {/*<HTMLText*/}
      {/*    content={i18n.language !== 'en' || !privatePage.text_eng ? privatePage.text : privatePage.text_eng}*/}
      {/*/>*/}
      {/*<Banner slug={privatePage.slug}/>*/}
    </>
  )
};

export default withRouter(PrivatePage);

// const HTMLText = memo(({content}) => (
//   <div dangerouslySetInnerHTML={{__html: content}} className='playlist--text'/>
// ));
