import React from 'react';

import PerPageSwitcher from './PerPageSwitcher';
import ViewSwitcher from './ViewSwitcher';
import HashtagCloud from './HashtagCloud';
import Pagination from './Pagination';
import CamerasPreviews from './CamerasPreviews';
import MapView from './MapView';
import Text from '../Text';
import './styles.scss';


class CamerasMenu extends React.Component {
    state = {
        perPage: parseInt(localStorage.getItem('perPage')) || 16,
        currentPage: 1,
        pagesCount: 0,
        showMap: false,
        filteredCameras: [],
        selectedTags: [],
        searchValue: '',
    };

    componentDidMount() {
        this.filterCameras();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.filteredCameras !== this.state.filteredCameras ||
            nextState.showMap !== this.state.showMap ||
            nextState.searchValue !== this.state.searchValue;
    }

    filterCameras = () => {
        const { selectedTags, perPage, currentPage, searchValue } = this.state;

        const filterCameraBySearchValue = (camera) => {
            if (!searchValue) {
                return true;
            }
            return camera.name.toLowerCase().includes(searchValue.toLowerCase());
        };

        const filterCameraByTags = (camera) => {
            if (!selectedTags.length) {
                return true;
            }
            return camera.tags.some(tag => selectedTags.includes(tag));
        }

        const paginateCameras = (cameras) => {
            const from = (currentPage - 1) * perPage;
            const until = perPage * currentPage;
            return cameras.slice(from, until);
        };

        const cameras = this.props.cameras.filter(camera => filterCameraBySearchValue(camera) && filterCameraByTags(camera));

        this.setState({
            pagesCount: Math.ceil(cameras.length / perPage),
            filteredCameras: this.state.showMap ? cameras : paginateCameras(cameras),
        });
    };

    setPerPage = (perPage) => this.setState({ perPage, currentPage: 1 }, () => {
        this.filterCameras();
        localStorage.setItem('perPage', perPage);
    });

    toggleMap = () => this.setState(prevState => ({ showMap: !prevState.showMap }), this.filterCameras);

    setCurrentPage = (page) => this.setState({ currentPage: page }, this.filterCameras);

    setSearchValue = (searchValue) => this.setState({ searchValue, currentPage: 1 }, this.filterCameras);

    changeSelectedTags = (tag) => {
        const { selectedTags } = this.state;
        const removeTag = () => selectedTags.filter(t => t !== tag);
        const addTag = () => [...selectedTags, tag];
        const tags = selectedTags.includes(tag) ? removeTag() : addTag();
        this.setState({ selectedTags: tags, currentPage: 1 }, this.filterCameras);
    };

    clearSelectedTags = () => this.setState({ selectedTags: [] }, this.filterCameras);

    render() {
        const { perPage, showMap, selectedTags, filteredCameras, currentPage, pagesCount, searchValue } = this.state;
        return (
            <>
                <div className="row cams-menu">
                    <div className="col-xs-5 col-lg-2 align-self-center cams-menu--text">
                        <Text text='installed_cams' />
                    </div>
                    <PerPageSwitcher perPage={showMap ? 0 : perPage} changePerPage={this.setPerPage} />
                    <ViewSwitcher showMap={showMap} toggleMap={this.toggleMap} />
                </div>
                <HashtagCloud
                    selectedTags={selectedTags}
                    changeSelectedTags={this.changeSelectedTags}
                    clearSelectedTags={this.clearSelectedTags}
                    searchValue={searchValue}
                    setSearchValue={this.setSearchValue}
                />
                {showMap ?
                    (<MapView cameras={filteredCameras} />) :
                    (<CamerasPreviews cameras={filteredCameras} setItem={this.props.setUpCamera} />)
                }
                <Pagination
                    pages={showMap ? 0 : pagesCount}
                    currentPage={currentPage}
                    setCurrentPage={this.setCurrentPage}
                />
            </>
        )
    }
}

export default CamerasMenu;
