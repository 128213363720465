import React, {memo} from 'react';

import {useTranslation} from "react-i18next";
import './styles.scss';

const AboutText = () => {
    const [t, i18n] = useTranslation();
    return (
        <div className="row">
            <div className="col about-text">
                <p>{t('about_header')}</p>
                <p>{t('about_first_paragraph')}</p>
                <p>{t('about_second_paragraph')}</p>
                <p>{t('about_third_paragraph')}</p>
            </div>
        </div>
    )
};

export default memo(AboutText, () => true);
