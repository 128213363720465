import React from 'react';

import './styles.scss';
import {useTranslation} from "react-i18next";

const Copyright = () => {
    const [t, i18n] = useTranslation();
    return (
        <div className="row">
            <div className="copyright">
                <a href='http://itim.net/' className="copyright--text">{t('developed_by')} <b>TIM ©</b></a>
            </div>
        </div>
    )
};

export default Copyright;