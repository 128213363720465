import React, {memo} from 'react';
import {useTranslation} from "react-i18next";

const Logout = ({user}) => {
    const [t, i18n] = useTranslation();
    const onSubmit = () => window.location.href = '/logout';
    return (
        <div className="justify-content-center">
            <p className='modal--text'>{t('logout_confirm_text')} "{user}"?</p>
            <div className='row justify-content-md-center'>
                <div className="col-md-3">
                    <button
                        type='submit'
                        className='modal--submit-button'
                        onClick={onSubmit}
                    >{t('logout')}
                    </button>
                </div>
            </div>
        </div>
    )

};

export default memo(Logout);