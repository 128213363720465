import React, {useContext} from 'react';

import {Map, TileLayer, Marker, Popup} from 'react-leaflet';
import Camera from '../../Camera';
import {CurrentCameraContext} from "../../../Context";

import 'leaflet/dist/leaflet.css';
import './styles.scss';

import L from 'leaflet';


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const MapView = ({cameras}) => {
    const [currentCamera, setUpCamera] = useContext(CurrentCameraContext);
    return (
        <div className="row map-wrapper">
            <div className="col-12 map" id="map">
                <Map center={currentCamera.coordinates} zoom={17} style={{width: '100%', height: '640px'}}>
                    <TileLayer
                        attribution=""
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                    />
                    {cameras.map((cam, i) => (
                        <Marker position={cam.coordinates} key={i}>
                            <Popup className='map--popup' autoPan={true}>
                                <div className="col-12">
                                    <Camera camera={cam} locale={'ua'} setUpCamera={setUpCamera} />
                                </div>
                            </Popup>
                        </Marker>
                    ))}
                </Map>
            </div>
        </div>
    )
};

export default MapView;
